import { useEffect, useState, useRef } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { ChatAvatorMe, ChatAvatorAssistant, ChatAvatorSystem, ChatAvatorServerMessage, ChatAvatorDocumentLink } from './ChatAvator';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { IconButton, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ja'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { nanoid } from 'nanoid'


import { TransitionAlerts } from './TransitionAlerts'

import { CategoryDefinition } from './Category'

let samplemessages = [
    /*{
        role: "user"
        , content: "aaa"
    },
    {
        role: "assistant"
        , content: "response"
    }*/
];

const apiUrlDoc = `${process.env.REACT_APP_API_DOC_URL}`;
const apiScope = `${process.env.REACT_APP_API_SCOPE}`;

const defaultParameter = {
    query: "ask your question"
}

const today = (new AdapterDayjs()).date();

const ChatStreamUI = () => {
    const isAuthenticated = useIsAuthenticated();
    const [parameterBase, setParameterBase] = useState(Object.assign({}, JSON.parse(JSON.stringify(defaultParameter))));
    const [query, setQuery] = useState("");
    const { instance } = useMsal();
    const [cog_token, setCogToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chatMessages, setChatMessages] = useState(samplemessages);
    const [releaseCategory, setReleaseCategory] = useState("GENERAL");// 初期値 一般
    const [fromDate, setFromDate] = useState(today.subtract(30, 'd')); // 初期値 今日から30日以内
    const [toDate, setToDate] = useState(today);                        // 初期値 今日

    const chatBox = useRef(null);
    const assistantBox = useRef(null);
    const [lastUpdateTime, setLastUpdateTime] = useState(new Date());  // 画面描画を強制Updateする
    const [sessionid, setSessionId] = useState(nanoid());
    const [api, setAPI] = useState(apiUrlDoc);
    const [notifyMessage, setNotifyMessage] = useState("")

    useEffect(() => {
        async function acquireOpenAIToken() {
            try {
                const account = instance.getAllAccounts()[0];
                if (!account) {
                    throw new Error("User not logged in.");
                }

                const tokenRequest = {
                    scopes: [apiScope],
                    account: account
                };
                //console.log( tokenRequest)
                try {
                    const response = await instance.acquireTokenSilent(tokenRequest);
                    setCogToken(response.accessToken);
                    return response.accessToken;
                } catch (error) {
                    console.error("Error acquiring token:", error);
                    throw error;
                }
            } catch (error) {
                console.error('Error acquiring OpenAI token or calling ChatCompletion API:', error);
            }
        }
        acquireOpenAIToken();
    }, [isAuthenticated]);

    // 画面描画を強制Updateするためにつかう
    const update = () => {
        setLastUpdateTime(new Date());
    }

    const fetchChatStream = async (headers, reqBody, callback) => {
        const response = await fetch(api, { headers: headers, method: "POST", body: JSON.stringify(reqBody) })

        const reader = response.body.getReader();

        if (!reader) return

        if (response.status >= 200 && response.status < 300) {
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                var decodedText = decoder.decode(value);
                var lines = decodedText.trim().split('data: ').map(line => line.trim()).filter(s => s).map((line) => {
                    return JSON.parse(line);
                });
                lines.map((e) => {
                    callback(e)
                });
            }
        } else {
            setNotifyMessage((new Date().toUTCString()) + " " + response.status + " " + response.statusText);
        }
    }

    const callback = (e) => {
        var msg = {}
        if (e.event === "message") {
            msg = { role: "message", "message": e.message }
            chatMessages.push(msg);
            setChatMessages(chatMessages);
        } else if (e.event === "delta_start") {
            msg.messages = [];
            let subMessage = {
                "role": 'assistant',
                "content": "",
                "answer_document": null,
                "reference": [],
                "page": null,
                "query": null,
                "id": e.id,
            }
            msg.messages.push(subMessage);
            chatMessages.push(msg);
            setChatMessages(chatMessages);
        } else if (e.event === "found_a_document") {
            msg = { role: "document", "document": e.document }
            chatMessages.push(msg);
            setChatMessages(chatMessages);
        } else if (e.event === "delta") {
            let lastMsg = chatMessages.slice(-1)[0] // 最後のResult
            lastMsg.messages[0].content += e.delta;
            assistantBox.current?.updateAssistantMessage();
        } else if (e.event === "result") {
            msg.messages = [];
            let subMessage = {
                "role": 'assistant',
                "content": e.result.answer,
                "answer_document": e.result.answer_document,
                "reference": e.result.reference,
                "page": e.result.page,
                "query": e.result.query,
                "id": e.id,
            }
            //console.log( subMessage)
            msg.messages.push(subMessage);
            chatMessages.push(msg);
            setChatMessages(chatMessages);
        } else if (e.event === "answer_document") {
            //console.log(e);
            let lastMsg = chatMessages.slice(-1)[0] // 最後のResult
            lastMsg.query = e.answer_document.query;
            assistantBox.current?.updateAssistantMessage();
        } else if (e.event === "reference") {
            let lastMsg = chatMessages.slice(-1)[0] // 最後のResult
            //console.log(lastMsg);
            //console.log(e.reference);
            lastMsg.reference = e.reference;
            assistantBox.current?.updateAssistantMessage();
        } else if (e.event === "error") {
            setNotifyMessage((new Date().toUTCString()) + " " + e.message);
        }
        update();
    }

    const sendChatMessage = async (msgObject) => {
        try {
            setIsLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + cog_token
            };

            const query = msgObject.query;
            const page = msgObject.page;
            const release_id = msgObject.release_id;
            const release_category = releaseCategory

            chatMessages.push({
                role: 'user',
                content: query,
            });
            setChatMessages(chatMessages);

            var reqBody = {
                "query": query,
                "session": sessionid,
                "release_id": release_id,
                "page": page,
                "release_category": release_category,
                "search_from": fromDate == null ? null : dateToInt(fromDate),
                "search_to": toDate == null ? null : dateToInt(toDate),
            };
            await fetchChatStream(headers, reqBody, callback)
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error calling ChatCompletion API:', error);

            setNotifyMessage((new Date().toUTCString()) + " " + error.stack);
        }
    };


    const requestOpenAiChat = async () => {
        if (!cog_token) return;
        if (query === "") return;

        setQuery("");

        await sendChatMessage({ "query": query });

    };
    const clearUserMessage = async () => {
        setQuery("");
    };

    const clearChat = async () => {
        setChatMessages([]);
        setParameterBase(parameterBase);
        setSessionId(nanoid());
    };

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        chatBox.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatMessages.length]);

    // DateTimeからint表現に変換する
    // 例） 2024/1/1 → 20240101
    // 例） 2024/12/31 → 20241231
    const dateToInt = (date) => {
        return Number(date.format("YYYYMMDD"));
    };

    const onChangeCategory = (newValue) => {
        setReleaseCategory(newValue)

        if (newValue === "GENERAL") {
            setFromDate(today.subtract(30, 'd'))
            setToDate(today);
        }
        else if (newValue === "PRODUCTIONSALES") {
            setFromDate(today.subtract(30, 'd'))
            setToDate(today);
        }
        else if (newValue === "IR") {
            setFromDate(today.subtract(90, 'd'))
            setToDate(today);
        }
        else if (newValue === "INTEGRATEDREPORT") {
            setFromDate(today.subtract(365, 'd'))
            setToDate(today);
        }
        else if (newValue === "SUSTAINABILITY") {
            setFromDate(today.subtract(365, 'd'))
            setToDate(today);
        }
    }

    return (
        <div className="homePage">
            <UnauthenticatedTemplate>
                <Box m={4} display="flex" alignItems="center" >
                    <Box width={'100%'}>
                        <Typography align='center'>
                            ログインして利用開始<NorthEastIcon />
                        </Typography>
                    </Box>
                </Box>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <>{notifyMessage.length > 0 ?
                    <TransitionAlerts message={notifyMessage}></TransitionAlerts>
                    : null}</>
                <Container maxWidth="lg" sx={{ p: 2 }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>

                            <Paper sx={{ p: 2 }}>
                                <Grid container>
                                    <Grid item xs="8">
                                        <Typography sx={{ fontWeight: 600, fontSize: 'large' }}>
                                            コーポレートコミュニケーション本部チャットボット
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Button onClick={clearChat}><ClearIcon />チャットをクリアする</Button>
                                    </Grid>
                                    <Grid item xs="12">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">

                                            <Select value={releaseCategory} onChange={(v) => { onChangeCategory(v.target.value) }}
                                                label="カテゴリ"
                                                variant="outlined"
                                                required
                                            >
                                                {
                                                    Object.keys(CategoryDefinition).map((k) => { return (<MenuItem value={k}>{CategoryDefinition[k]}</MenuItem>) })
                                                }
                                            </Select>
                                            対象日付
                                            <DatePicker value={fromDate} onChange={(v) => { setFromDate(v) }}
                                                label="検索対象リリース日(From)"
                                                required
                                                margin="dense" />
                                            ~
                                            <DatePicker value={toDate} onChange={(v) => { setToDate(v) }}
                                                label="検索対象リリース日(To)"
                                                required
                                                margin="dense" />

                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Box sx={{ height: 'calc(100vh - 400px)', backgroundColor: 'rgb(250, 249, 248)', overflowY: 'scroll' }}>
                                    {chatMessages.map((value) => {
                                        if (value != null) {
                                            try {
                                                if (value.role === "user") {
                                                    return (<ChatAvatorMe message={value} />)
                                                } else if (value.role === "error") {
                                                    return (<ChatAvatorSystem message={value} />)
                                                } else if (value.role === "message") {
                                                    return (<ChatAvatorServerMessage message={value} />)
                                                } else if (value.role === "document") {
                                                    return (<ChatAvatorDocumentLink document={value} cogToken={cog_token} />)
                                                } else {
                                                    // assistant message(s)
                                                    return (<ChatAvatorAssistant
                                                        sessionid={sessionid}
                                                        message={value}
                                                        lupd={lastUpdateTime}
                                                        //apiMode={apiMode}
                                                        cogToken={cog_token}
                                                        chatMessages={chatMessages}
                                                        updateCallback={update}
                                                        sendChatMessage={sendChatMessage} ref={assistantBox} />)
                                                }
                                            } catch (error) {
                                                return (<ChatAvatorSystem message={value} />)
                                            }
                                        }
                                    })}
                                    <div ref={chatBox} />
                                </Box>
                                <Box sx={{ py: 2 }}>
                                    <Paper sx={{ p: 2 }}>
                                        <Grid container spacing={1}>
                                            <Grid item >
                                                <Typography sx={{ fontWeight: 600 }}>User message</Typography>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <TextareaAutosize style={{ width: '100%', resize: 'none' }} type="text"
                                                        placeholder="聞きたい内容を入れてください" minRows={4} value={query} onChange={(e => setQuery(e.target.value))} />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {
                                                        isLoading ? (
                                                            <CircularProgress size={30} />
                                                        ) : (
                                                            <IconButton
                                                                onClick={requestOpenAiChat}
                                                                disabled={!releaseCategory}
                                                            ><SendIcon /></IconButton>
                                                        )
                                                    }
                                                    <IconButton onClick={clearUserMessage}><ClearIcon /></IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>

                </Container>
            </AuthenticatedTemplate >
        </div >

    )
};

export default ChatStreamUI