import { useEffect, useState } from 'react'
import { FormControl, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CircularProgress from '@mui/material/CircularProgress';

import { MuiFileInput } from 'mui-file-input';
import { TransitionAlerts } from './TransitionAlerts'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ja'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { CategoryDefinition } from './Category'

const apiUploadRelease = `${process.env.REACT_APP_API_UPLOAD_RELEASE}`;

const UploadRelease = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [processingMessage, setProcessingMessage] = useState("");
    const [notifyMessage, setNotifyMessage] = useState("")

    const [releaseTitle, setReleaseTitle] = useState("")
    const [releaseDate, setReleaseDate] = useState(null)
    const [releaseCategory, setReleaseCategory] = useState("");
    const [file1, setFile1] = useState(null)
    const [file2, setFile2] = useState(null)
    const [file3, setFile3] = useState(null)
    const [file4, setFile4] = useState(null)

    const cog_token = props.cog_token;

    const upload = async () => {
        try {
            setIsLoading(true);
            var msg = "";
            setProcessingMessage(msg)

            const headers = {
                'Authorization': `Bearer ` + cog_token
            };

            const formData = new FormData();
            formData.append("release_title", releaseTitle);
            formData.append("release_date", Number(releaseDate.format("YYYYMMDD")));
            formData.append("release_category", releaseCategory);

            if (file1 != null)
                formData.append('files', file1);
            if (file2 != null)
                formData.append('files', file2)
            if (file3 != null)
                formData.append('files', file3)
            if (file4 != null)
                formData.append('files', file4)


            const response = await fetch(apiUploadRelease, { headers: headers, method: "POST", body: formData })

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            if (!reader) return

            if (response.status >= 200 && response.status < 300) {


                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    var decodedText = decoder.decode(value);
                    var lines = decodedText.trim().split('data: ').map(line => line.trim()).filter(s => s).map((line) => {
                        return JSON.parse(line);
                    });
                    lines.map((e) => {
                        msg += e.event + "\r\n";
                        setProcessingMessage(msg);
                    });
                }
            } else {
                const { done, value } = await reader.read();
                var decodedTextError = decoder.decode(value);
                var responseObject = JSON.parse(decodedTextError)
                if (responseObject != null) {
                    setNotifyMessage((new Date().toUTCString()) + " " + responseObject.message);
                } else {
                    setNotifyMessage((new Date().toUTCString()) + " " + response.status + " " + response.statusText);
                }
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error calling Release Registration API:', error);
            setNotifyMessage((new Date().toUTCString()) + " " + error.stack);
        }
    };
    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <Paper sx={{ p: 2 }} >
                <>{notifyMessage.length > 0 ?
                    <TransitionAlerts message={notifyMessage}></TransitionAlerts>
                    : null}</>
                <Typography sx={{ fontWeight: 600, fontSize: 'large' }}>ファイルアップロード</Typography>
                <Typography sx={{ fontSize: 'small' }}>
                    リリースタイトル、リリース日は必須です。<br />
                    メインリリース文書または、添付文書1~3いずれかは必須です。<br />
                    枠内をクリックすることでファイルを選択できます。<br />
                    ファイルがない状態では登録できません。<br />
                </Typography>
                <FormControl >
                    <InputLabel id="category-label">カテゴリ*</InputLabel>
                    <Select value={releaseCategory} onChange={(v) => { setReleaseCategory(v.target.value) }}
                        labelId="category-label"
                        label="カテゴリ"
                        variant="outlined"
                        required
                        >
                        {
                            Object.keys(CategoryDefinition).map((k)=>{return( <MenuItem value={k}>{CategoryDefinition[k]}</MenuItem> )})
                        }                            
                    </Select>
                    <TextField value={releaseTitle} onChange={(v) => { setReleaseTitle(v.target.value) }}
                        label="リリースタイトル"
                        variant="outlined"
                        required
                        margin="dense" />
                    <DatePicker value={releaseDate} onChange={(v) => { setReleaseDate(v) }}
                        label="リリース日"
                        required
                        margin="dense" />
                    <MuiFileInput value={file1} onChange={(newFile) => { setFile1(newFile) }}
                        label="メインリリース文書"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ accept: '.pdf' }} />
                    <MuiFileInput value={file2} onChange={(newFile) => { setFile2(newFile) }}
                        label="定QA集など添付文書1"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ accept: '.pdf' }} />
                    <MuiFileInput value={file3} onChange={(newFile) => { setFile3(newFile) }}
                        label="定QA集など添付文書2"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ accept: '.pdf' }} />
                    <MuiFileInput value={file4} onChange={(newFile) => { setFile4(newFile) }}
                        label="定QA集など添付文書3"
                        variant="outlined"
                        margin="dense"
                        inputProps={{ accept: '.pdf' }} />
                    <>
                        {isLoading ? (
                            <Typography align='center'><CircularProgress size={30} /></Typography>
                        ) : (
                            <Button onClick={upload}
                                startIcon={<FileUploadIcon />}
                                variant="outlined"
                                disabled={!releaseTitle || !releaseDate || !releaseCategory}
                            > アップロード</Button >
                        )
                        }
                    </>

                    <div sx={{ fontSize: 'small' }}>
                        <TextareaAutosize style={{ width: '100%', resize: 'none' }} type="text"
                            minRows={10} value={processingMessage} readOnly />
                    </div>

                </FormControl>
            </Paper>
        </LocalizationProvider >
    )
};

export default UploadRelease