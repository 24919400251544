import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMsal } from "@azure/msal-react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser } from '@fortawesome/free-solid-svg-icons'

// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import { loginRequest } from '../authConfig';
import { callMsGraph } from '../graph';
//import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PersonIcon from '@mui/icons-material/Person';
const Profile = () => {
    const { instance, accounts } = useMsal();
    const [prof, setProf] = useState("loading...");
    const [graphData, setGraphData] = useState(null);
    const [token, setToken] = useState();

    useEffect(() => {
        if (accounts && accounts[0]) {
            // Silently acquires an access token which is then attached to a request for MS Graph data
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    setToken(response.accessToken);
                    callMsGraph(response.accessToken).then((response) => setGraphData(response));
                })
                .then(setProf(accounts[0].name));
        }
    }, [accounts, instance]);

    const logout = () => {
        instance.logout({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        });
    };

    return (
        <>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant="contained" {...bindTrigger(popupState)}>
                            <PersonIcon ></PersonIcon>{" " + prof}
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </>
    );
}

export default Profile