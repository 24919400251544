import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

const Login = () => {
    const { instance } = useMsal();

    const navigate = useNavigate();
    const loginWithAzureAD = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
        //instance.loginPopup(loginRequest).catch((e) => {
        //    console.log(e);
        //});
        navigate("/");
    };
    return (
        <Button onClick={loginWithAzureAD} color="inherit">
            <LoginIcon /> ログイン
        </Button>
    )
}

export default Login