import React from 'react'
import { Link } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Profile from './Profile';
import Login from './Login';

import ChatIcon from '@mui/icons-material/Chat';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const Navbar = (props) => {
    const isAuthenticated = useIsAuthenticated();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Mazda AI Communicator
                    </Typography>
                    <Box>

                        <Button component={Link} to="/" sx={{color:'white'}} startIcon={<ChatIcon/>}>
                            チャット
                        </Button>
                        <Button component={Link} to="/release-files" sx={{color:'white'}} startIcon={<UploadFileIcon/>}>
                            文書登録
                        </Button>
                    </Box>
                    {!isAuthenticated ? <Login /> : <Profile />}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Navbar