import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChatStreamUI from './components/ChatStreamUI';
import ReleaseFiles from './components/ReleaseFiles'
// import Home_Backup from './components/Home_Backup';
import Navbar from './components/Navbar';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<ChatStreamUI/>}></Route>
        <Route path="/release-files" element={<ReleaseFiles/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;