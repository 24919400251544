import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import Markdown from 'react-markdown'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import CircularProgress from '@mui/material/CircularProgress';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/History';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const apiUrlDownload = `${process.env.REACT_APP_API_DOWNLAOD_URL}`;
const apiLike = `${process.env.REACT_APP_API_LIKE_URL}`
const apiDisLike = `${process.env.REACT_APP_API_DISLIKE_URL}`
const apiLikeOff = `${process.env.REACT_APP_API_LIKEOFF_URL}`
const apiComment = `${process.env.REACT_APP_API_COMMENT_URL}`

export const ChatAvatorMe = (props) => {

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs>  </Grid>
                <Grid item xs={7}>
                    <Paper sx={{ p: 2 }}>
                        {props.message.content}
                    </Paper>
                </Grid>
                <Grid item xs={1}>
                    <PersonIcon sx={{ fontSize: "3rem" }} />
                </Grid>
            </Grid>

        </Box>
    )
}


export const ChatAvatorAssistant = forwardRef((props, ref) => {
    const [type, setType] = useState("simple");
    const [message, setMessage] = useState("");

    const [messageId, setMessageId] = useState("");
    const [like, setLike] = useState(0);
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const sessionid = props.sessionid;
    const cog_token = props.cogToken;
    const sendChatMessage = props.sendChatMessage;

    /**
     * 親コンポーネントから強制描画させるためのハンドラ
     * https://zenn.dev/yubachiri/articles/7a11545d8d7ae6
     */
    useImperativeHandle(ref, () => ({
        updateAssistantMessage: () => {
            setAsssitantMessage();
        },
    }));


    const setAsssitantMessage = () => {
        if (props.message.message != undefined) {
            setType("simple");
            setMessage(props.message.message.content)
        } else if (props.message.messages != undefined) {
            setType("extension");
            //console.log(props.message)
            props.message.messages.map((msg) => {
                //console.log(msg)
                if (msg.role == 'assistant') {
                    //console.log(msg)
                    setMessage(msg.content);
                    setMessageId(msg.id);
                }
            });
        }
    }

    useEffect(() => {
        setAsssitantMessage();
    }, []);

    const downloadFile = (filepath) => {
        //console.log(filepath);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + cog_token,
        };

        var url = apiUrlDownload + "?path=" + encodeURIComponent(filepath)

        fetch(url, { headers: headers }) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
                console.log(err);
            });
    }

    const likeClicked = async () => {
        var newLike = like;
        if (like == -1)
            newLike = 1
        if (like == 0)
            newLike = 1
        if (like == 1)
            newLike = 0

        setLike(newLike)
        sendLike(newLike)
    }
    const dislikeClicked = async () => {
        var newLike = like;
        if (like == -1)
            newLike = 0
        if (like == 0)
            newLike = -1
        if (like == 1)
            newLike = -1

        setLike(newLike)
        sendLike(newLike)
    }
    const sendLike = async (v) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + cog_token,
        };

        var api = apiLike
        if (v == -1)
            api = apiDisLike;
        else if (v == 0)
            api = apiLikeOff;
        else if (v == 1)
            api = apiLike;

        api = api + "/" + sessionid + "/" + messageId;
        const response = await fetch(api, { headers: headers, method: "GET" })
        const result = response.json();
        console.log(result)
    }

    const sendComment = async () => {
        setIsLoading(true)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + cog_token,
        };

        var api = apiComment;

        var body = {
            "session_id": sessionid,
            "message_id": messageId,
            "comment": comment
        }
        const response = await fetch(api, { headers: headers, method: "POST", body: JSON.stringify(body) })
        const result = response.json();
        console.log(result)
        setIsLoading(false)
    }


    const searchInRelease = (query, release_id) => {
        sendChatMessage({ "query": query, "release_id": release_id })
    }

    return (
        <Box sx={{ p: 1 }}>
            <Grid container>
                <Grid item xs={1}>
                    <SmartToyIcon sx={{ fontSize: "3rem" }} />
                </Grid>
                <Grid item xs={7}>
                    <Card sx={{ p: 1 }}>
                        {type == "simple" ?
                            (
                                <CardContent>
                                    <Markdown>{message}</Markdown>
                                </CardContent>
                            )
                            :
                            (
                                <>
                                    <CardContent sx={{ p: 1 }} >
                                        <Markdown>{message}</Markdown>
                                        {
                                            props.message.reference != null ? props.message.reference.map((r, index) => {
                                                return (
                                                    <>
                                                        <Card sx={{ my: 1, py: 1 }} variant="outlined">
                                                            <CardContent sx={{ my: 0, py: 0 }}>
                                                                <Typography>{r.title}</Typography>
                                                                {
                                                                    r.documents.map((d, docIndex) => {
                                                                        return (<><br /><Button href="#" onClick={() => { downloadFile(d.objectid) }}><Typography fontSize={"small"}><AttachFileIcon fontSize="small" />{d.source}</Typography></Button></>)
                                                                    })
                                                                }
                                                            </CardContent>
                                                            <CardActions>
                                                                {
                                                                    index == 0 ? (
                                                                        <Typography fontSize={"small"}>このリリースを参照に回答を生成しています。</Typography>
                                                                    ) : (
                                                                        <Button href="#" onClick={() => { searchInRelease(props.message.query, r.release_id) }}>このリリースセットを対象に再問合せ</Button>
                                                                    )
                                                                }
                                                            </CardActions>
                                                        </Card>
                                                    </>
                                                )
                                            }) : null
                                        }
                                    </CardContent>
                                    {
                                        message.length > 0 ?
                                            <CardActions>
                                                <Box sx={{ width: '100%' }}>
                                                    <Box sx={{ py: 0 }} >
                                                        {like == -1 ? <>
                                                            <IconButton onClick={likeClicked}><ThumbUpOffAltIcon></ThumbUpOffAltIcon></IconButton>
                                                            <IconButton onClick={dislikeClicked}><ThumbDownAltIcon></ThumbDownAltIcon></IconButton>
                                                        </> : null}
                                                        {like == 0 ? <>
                                                            <IconButton onClick={likeClicked}><ThumbUpOffAltIcon></ThumbUpOffAltIcon></IconButton>
                                                            <IconButton onClick={dislikeClicked}><ThumbDownOffAltIcon></ThumbDownOffAltIcon></IconButton>
                                                        </> : null}

                                                        {like == 1 ? <>
                                                            <IconButton onClick={likeClicked}><ThumbUpAltIcon></ThumbUpAltIcon></IconButton>
                                                            <IconButton onClick={dislikeClicked}><ThumbDownOffAltIcon></ThumbDownOffAltIcon></IconButton>
                                                        </> : null}

                                                        {/* {
                                                            <>
                                                                <IconButton onClick={() => { openNewWindow("/api/history/" + sessionid, "_blank"); }}><HistoryIcon></HistoryIcon></IconButton>
                                                                <IconButton onClick={() => { openNewWindow("/api/trace/" + sessionid, "_blank"); }}><ContentPasteSearchIcon></ContentPasteSearchIcon></IconButton>
                                                            </>
                                                        } */}

                                                    </Box>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs>
                                                            <TextareaAutosize style={{ width: '100%', resize: 'none' }} type="text"
                                                                placeholder="回答内容についてコメントがあればご記入ください。" minRows={2} value={comment} onChange={(e => setComment(e.target.value))} />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {
                                                                isLoading ? (
                                                                    <CircularProgress size={30} />
                                                                ) : (
                                                                    <IconButton onClick={sendComment}><SendIcon /></IconButton>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </CardActions>
                                            : null
                                    }
                                </>
                            )
                        }
                    </Card>
                </Grid>
                <Grid item xs>  </Grid>
            </Grid>

        </Box>
    )

});



export const ChatAvatorSystem = (props) => {

    return (
        <Box sx={{ p: 1 }}>
            <Grid container>
                <Grid item xs={1}>
                    <ErrorOutlineIcon sx={{ fontSize: "3rem" }} />
                </Grid>
                <Grid item xs={7}>
                    <Paper sx={{ p: 2 }}>
                        <span style={{ color: "red" }}>{props.message.content}</span>
                    </Paper>
                </Grid>
                <Grid item xs>  </Grid>
            </Grid>
        </Box>
    )

}


export const ChatAvatorServerMessage = (props) => {

    return (
        <Box sx={{ mx: 1 }}>
            <span><CheckIcon fontSize='smaller'></CheckIcon>{props.message.message}</span>
        </Box>
    )

}



export const ChatAvatorDocumentLink = (props) => {

    const doc = props.document.document;
    const cog_token = props.cogToken;;

    const downloadFile = (filepath) => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + cog_token,
        };

        var url = apiUrlDownload + "?path=" + encodeURIComponent(filepath)

        fetch(url, { headers: headers }) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
                console.log(err);
            });
    }
    const formatDate = (date) => {
        // 20241231のような数値を日付文字列に変換する
        const year = Math.trunc(date / 10000)
        const month = Math.trunc((date - year * 10000) / 100)
        const day = Math.trunc(date - year * 10000 - month * 100)
        return year + "/" + month + "/" + day;
    }
    return (
        <Box sx={{ mx: 1 }}>
            <span><CheckIcon fontSize='smaller'></CheckIcon>{ formatDate(doc.release_date)} {doc.title} <Button href="#" sx={{ p: 0 }} onClick={() => { downloadFile(doc.objectid) }}>{doc.source} </Button></span>
        </Box>
    )

}
